/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $( "<span class='dropdown-arrow'></span>" ).prependTo( "#menu-primary-navigation >.add-dropdown" );
        // Set options for header menu
        var options = {
          offset: '.wrap',
          offsetSide: 'top',
          classes: {
              clone:   'banner--clone',
              stick:   'banner--stick',
              unstick: 'banner--unstick'

          }
        };
        // Initialise with options
        var banner = new Headhesive('.banner', options);
        // JavaScript to be fired on all pages
        $(".widget_newsletterwidget .tnp-email").attr("placeholder", "Enter your email");
        $("#mobile-navigation").mmenu({
           // options
          extensions: ["effect-menu-slide"],
          navbar: {
            add: false, 
            title: "Menu"
          },
          offCanvas: {
            position: "right", 
            zposition: "front"
          },
          slidingSubmenus: true
        }, {
           //configuration
           offCanvas: {
              pageSelector: "#outer-page-wrapper"
           }, 
           clone: false
        });

        var API = $("#mobile-navigation").data( "mmenu" );
        $("#mobile-nav-toggle").click(function() {
          API.close();
        });

        //Initilize Owl Carousel
        $('.owl-carousel').owlCarousel({
          margin:25,
          loop:true,
          autoplay:true,
          autoplayTimeout:3000,
          autoplayHoverPause:true,
          nav: true,
          navContainer: '.owl-nav-container',
          navText: [
            "<svg version='1.1' id='prev_svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='1313.841 1467.131 57.659 109.265' enable-background='new 1313.841 1467.131 57.659 109.265' xml:space='preserve'><g><g><polygon points='1317.67,1523.655 1363.52,1569.139 1367.67,1565.355 1324.029,1521.764 1367.525,1478.171 1363.543,1474.388 1317.67,1519.872'/></g></g></svg>",
            "<svg version='1.1' id='next_svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='1313.841 1467.131 57.659 109.265' enable-background='new 1313.841 1467.131 57.659 109.265' xml:space='preserve'><g><g><polygon points='1367.67,1519.872 1321.797,1474.388 1317.814,1478.171 1361.311,1521.764 1317.67,1565.355 1321.82,1569.139 1367.67,1523.655'/></g></g></svg>"
          ],
          responsive:{
            0:{
              items:1,
              pager: true
            },
            768:{
              items: 2, 
              pager: true
            },
            992:{
              items: 4, 
              pager: true
            }
          }
        });
        //BEGIN Active Same Page and Other Page Jumps
        var jump = function(e){
          var target = location.hash; // jshint ignore:line
          var h = $('#primary-header').outerHeight();
          $.smoothScroll({
            offset: -h,
            scrollTarget: target, 
            afterScroll: function (){
              location.hash = target;
            }
          });
        };
        $('.menu-item a').on('click',function (e) {
          var current = window.location.href.split('#')[0];
          var linkHref = $(this).attr('href').split('#')[0];
          if (current === linkHref && this.hash) {
            e.preventDefault();
            var target = this.hash; // jshint ignore:line
            var h = $('#primary-header').outerHeight();
            $.smoothScroll({
              offset: -h,
              scrollTarget: target, 
              afterScroll: function (){
                if(history.pushState) {
                    history.replaceState(null, null, target);
                }
                else {
                    location.hash = target;
                }
              }
            });
          }
        });
        if (location.hash){
          jump();
        }
        //END Active Same Page and Other Page Jumps

        var wow = new WOW(
          {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
            callback:     function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null // optional scroll container selector, otherwise use window
          }
        );
        wow.init();

        // JavaScript to be fired on the News Article Archive Page
        var $gridContainer = $('.isotope'),
          $select = $('select.filter');
        filters = {};
        // Fire Isotope only when images are loaded
        $gridContainer.imagesLoaded( function(){
          $gridContainer.isotope({
            itemSelector: '.isotope-item', 
            layoutMode: 'fitRows'
          });
          $gridContainer.isotope();
        });
        // filter items when filter drop down item is clicked
        $select.change(function() {
          var $this = $(this);
          var $optionSet = $this;
          var group = $optionSet.attr('data-group');
          filters[group] = $this.find('option:selected').attr('data-filter');

          var isoFilters = [];
          for (var prop in filters) {
            isoFilters.push(filters[prop]);
          }
          var selector = isoFilters.join('');
          $gridContainer.isotope({
            filter: selector
          });
          var hashVal = "#" + selector.substring(1);
          location.hash = hashVal;
          return false;
        });

        //Expanding Search Form functionality
        $('a#search-toggle').click(function (e) {
          $('.primary-search-form').slideToggle();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'home': {
      init: function() {
        //Initialize Number Counter
        $('.counter').counterUp();
      }
    },
    'investment_overview': {
      init: function() {
        // JavaScript to be fired on single company pages
        $('.read-more').readmore({
          speed: 75,
          lessLink: '<a href="#">Read less</a>', 
          collapsedHeight: 242
        });
      }
    },
    'single_company': {
      init: function() {
        // JavaScript to be fired on single company pages
        $('.read-more').readmore({
          speed: 75,
          lessLink: '<a href="#">Read less</a>', 
          collapsedHeight: 242
        });
      }
    },
    'post_type_archive_team_member':{
      init: function() {
        // Store # parameter and add "." before hash
        var hashID = "." + window.location.hash.substring(1);
        var hashVal = window.location.hash.substring(1);
        // Function to run after isotope items have been arranged
        function onArrange() {
          var hashVal = window.location.hash.substring(1);
          $(".grid-filter-container .filter").val(hashVal);
        }
        // If hash fragment exists, then filter
        if(window.location.hash) {
        // initiating the isotope page
          $(window).load(function(){
            var $gridContainer = $('.isotope');
            $gridContainer.imagesLoaded(function(){
              $gridContainer.isotope({
                itemSelector: ".isotope-item",
                layoutMode: 'fitRows',
                filter: hashID // the variable filter hack
              });             
            });
            // Even though the isotope filtering has been triggered, the option still has to be selected the value.
            $gridContainer.on( 'arrangeComplete', onArrange );
          });
        }
        $('.menu-item a').on('click',function (e) {
          var current = window.location.href.split('#')[0];
          var linkHref = $(this).attr('href').split('#')[0];
          if (current === linkHref && this.hash) {
            // Store # parameter and add "." before hash
            var hashID = "." + this.hash.substring(1);
            var hashVal = window.location.hash.substring(1);
            var $gridContainer = $('.isotope');
            $gridContainer.imagesLoaded(function(){
              $gridContainer.isotope({
                itemSelector: ".isotope-item",
                layoutMode: 'fitRows',
                filter: hashID // the variable filter hack
              });    
            });
            // Even though the isotope filtering has been triggered, the option still has to be selected the value.
            $gridContainer.on( 'arrangeComplete', onArrange );  
          }
        });
      }
    },
    'post_type_archive_company':{
      init: function() {
        // Store # parameter and add "." before hash
        var hashID = "." + window.location.hash.substring(1);
        var hashVal = window.location.hash.substring(1);
        // Function to run after isotope items have been arranged
        function onArrange() {
          var hashVal = window.location.hash.substring(1);
          $(".grid-filter-container .filter").val(hashVal);
        }
        // If hash fragment exists, then filter
        if(window.location.hash) {
        // initiating the isotope page
          $(window).load(function(){
            var $gridContainer = $('.isotope');
            $gridContainer.imagesLoaded(function(){
              $gridContainer.isotope({
                itemSelector: ".isotope-item",
                layoutMode: 'fitRows',
                filter: hashID // the variable filter hack
              });             
            });
            // Even though the isotope filtering has been triggered, the option still has to be selected the value.
            $gridContainer.on( 'arrangeComplete', onArrange );
          });
        }
        $('.menu-item a').on('click',function (e) {
          var current = window.location.href.split('#')[0];
          var linkHref = $(this).attr('href').split('#')[0];
          if (current === linkHref && this.hash) {
            // Store # parameter and add "." before hash
            var hashID = "." + this.hash.substring(1);
            var hashVal = window.location.hash.substring(1);
            var $gridContainer = $('.isotope');
            $gridContainer.imagesLoaded(function(){
              $gridContainer.isotope({
                itemSelector: ".isotope-item",
                layoutMode: 'fitRows',
                filter: hashID // the variable filter hack
              });    
            });
            // Even though the isotope filtering has been triggered, the option still has to be selected the value.
            $gridContainer.on( 'arrangeComplete', onArrange );  
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
